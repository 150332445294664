const initialState = () => ({
  products: [],
  cart: [],
  total: 0,
});

export default {
  namespaced: true,

  state: initialState(),

  getters: {
    productOnCart(state) {
      return state.cart.map((item) => ({
        ...item,
      }));
    },

    cartTotalUrgent(state, getters) {
      return getters.productOnCart.reduce(
        (total, current) => total + current.unit_price_urgent * current.clothes_quantity,
        0,
      );
    },

    cartTotal(state, getters) {
      return getters.productOnCart.reduce(
        (total, current) => total + current.unit_price * current.clothes_quantity,
        0,
      );
    },
  },

  mutations: {
    addProduct(state, item) {
      const exists = state.products.find((p) => p.product.uuid === item.product.uuid);

      if (!exists) {
        state.products.push(item);
      }
    },

    removeAllProducts(state) {
      state.products = [];
    },

    removeProduct(state, product) {
      state.products = state.products.filter((p) => p !== product);
    },

    updateQuantity(state, { item, operation }) {
      const product = state.products.find((p) => p === item);
      product.quantity += operation;

      if (product.quantity <= 0) {
        product.quantity = 1;
      }
    },
    /* eslint-disable no-param-reassign */
    incrementProduct(state, product) {
      /* eslint-disable no-plusplus */
      product.clothes_quantity++;
    },
    decrementProduct(state, product) {
      /* eslint-disable no-plusplus */
      product.clothes_quantity--;
    },
    /* eslint-enable no-param-reassign */

    addProductToCart(state, product) {
      const productData = {
        clothes_quantity: product.clothes_quantity ? product.clothes_quantity : 1,
        id: product.id ? product.id : product.uuid,
        key: product.key,
        name: product.name,
        unit_price: product.unit_price_normal ? product.unit_price_normal : product.unit_price,
        unit_price_urgent: product.unit_price_urgent,
        img_url: product.img_url,
      };
      if (product?.service?.name) {
        productData.service = product.service.name;
        productData.service_id = product.service.id;
      } else {
        productData.service = product.service;
        productData.service_id = product.service_id;
      }
      state.cart.push(productData);
    },
    removeProductCart(state, index) {
      state.cart.splice(index, 1);
    },
  },

  actions: {
    addProductoCar(context, product) {
      const item = context.state.cart.find((p) => p.id === product.uuid);
      if (item) {
        context.commit('incrementProduct', item);
      } else {
        context.commit('addProductToCart', product);
      }
    },

    incrementProductcart(context, product) {
      const item = product;
      if (item.clothes_quantity >= 0) {
        context.commit('incrementProduct', item);
      }
    },

    decrementProductcart(context, product, index) {
      const item = product;
      if (item.clothes_quantity > 1) {
        context.commit('decrementProduct', item);
      } else {
        context.commit('removeProductCart', index);
      }
    },

    removeProductFromCart(context, index) {
      context.commit('removeProductCart', index);
    },
  },
};
