const initialState = () => ({
  address: {
    street: null,
    outer_number: null,
    inner_number: null,
    suburb: null,
    municipality: null,
    city: null,
    state: null,
    country: null,
    postal_code: null,
  },
  customer: {
    email: null,
    mobile_phone: null,
    name: null,
    password: null,
    postal_code: null,
    uuid: null,
  },
  date: null,
  discount: null,
  individualSelection: false,
  office: {},
  offices: [],
  products: [],
  step: 1,
  time_end: null,
  time_start: null,
  total: 0,
});

export default {
  namespaced: true,

  state: initialState(),

  getters: {
    total(state) {
      if (!state.individualSelection) return 35;

      let total = state.products.reduce(
        // eslint-disable-next-line no-return-assign, no-param-reassign
        (sum, item) => (sum += item.product.unit_price_normal * item.quantity),
        0,
      );

      if (state.discount) {
        const discountAmount = total * (state.discount.discount / 100);
        total -= discountAmount;
      }

      return total < 0 ? 0 : total;
    },
  },

  mutations: {
    setAddress(state, address) {
      state.address = address;
    },

    setCustomer(state, customer) {
      state.customer = customer;
      if (!state.address.postal_code && customer.postal_code) {
        state.address.postal_code = customer.postal_code;
      }
    },

    setDiscount(state, discount) {
      state.discount = discount;
    },

    setOffice(state, office) {
      state.office = office;
    },

    setOffices(state, offices) {
      state.offices = offices;
    },

    setStep(state, step) {
      state.step = step;
    },

    nextStep(state) {
      state.step += 1;
    },

    previousStep(state) {
      state.step -= 1;
    },

    addProduct(state, item) {
      const exists = state.products.find((p) => p.product.uuid === item.product.uuid);

      if (!exists) {
        state.products.push(item);
      }
    },

    removeAllProducts(state) {
      state.products = [];
    },

    removeProduct(state, product) {
      state.products = state.products.filter((p) => p !== product);
    },

    updateQuantity(state, { item, operation }) {
      const product = state.products.find((p) => p === item);
      product.quantity += operation;

      if (product.quantity <= 0) {
        product.quantity = 1;
      }
    },

    reset(state) {
      const newState = initialState();

      Object.keys(state).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
};
