// API
import BaseAPI from './base';

/**
 * Implement Core API
 */
export default class EnviosApi extends BaseAPI {
  constructor() {
    super('envios');
  }

  /**
  * Obtener todos los Envios Disponibles
  */
  enviosAll(params) {
    return this.request.get(`${this.baseURI}`, { params });
  }

  envios(params, id) {
    return this.request.get(`${this.baseURI}/${id}`, { params });
  }
}
