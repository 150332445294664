<template>
  <v-container fluid class="no_margins">
    <section class="section-default">
      <Loader />
      <router-view />
    </section>
  </v-container>
</template>

<script>
// Components
import Loader from '@/components/loader/index.vue';

export default {
  name: 'App',
  components: {
    Loader,
  },

  // async created() {
  //   await this.$store.dispatch('auth/verifyToken');
  // },
};
</script>

<style lang="scss" scoped>
.section-default {
  min-height: 100vh;
  min-width: 100vw;
}
// .section-navbar {
//   margin-bottom: 105px;
// }
.no_margins {
  padding: 0 !important;
  margin: 0 !important;
}
</style>
