const initialState = () => ({
});

export default {
  namespaced: true,

  state: initialState(),

  getters: {},
  mutations: {
  },

  actions: {
  },
};
