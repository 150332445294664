// Libraries
import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
import vuetify from '@/plugins/vuetify'; // path to vuetify export
import VuePaginate from 'vue-paginate';
import VueFormulate from '@braid/vue-formulate';
// import { cu } from '@braid/vue-formulate-i18n';
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

// Local JS
import '@/filters';
import '@/plugins/vee-validate';
import vue2filtersConfig from '@/plugins/vue2filters';
import RoleMixin from '@/mixins/RoleMixin';
import router from '@/router';
import store from '@/store';
import AOS from 'aos';

// Local Styles
import '@/assets/scss/app.scss';
import 'aos/dist/aos.css';
// scss/main.scss
import '../node_modules/@braid/vue-formulate/themes/snow/snow.scss';
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';
// import 'vuetify/dist/vuetify.min.css';

// Components
import App from '@/App.vue';
import Form from '@/components/validation/Form.vue';
import ComboField from '@/components/validation/ComboField.vue';
import FileField from '@/components/validation/FileField.vue';
import SelectField from '@/components/validation/SelectField.vue';
import TextAreaField from '@/components/validation/TextAreaField.vue';
import TextField from '@/components/validation/TextField.vue';

import config from '@/config';
import * as VueGoogleMaps from 'vue2-google-maps'

import GmapCluster from 'vue2-google-maps/src/components/autocompleteImpl'

// Global Components
Vue.component('Form', Form);
Vue.component('ComboField', ComboField);
Vue.component('FileField', FileField);
Vue.component('SelectField', SelectField);
Vue.component('TextAreaField', TextAreaField);
Vue.component('TextField', TextField);

// Plugins
Vue.use(Vue2Filters, vue2filtersConfig);
Vue.use(VueFormulate);

Vue.use(VueGoogleMaps, {
  load: {
    key: config.MAPS_API_KEY,
    libraries: 'places',
    region: 'MX',
    language: 'mx',
  },
})

Vue.component('GmapCluster', GmapCluster)
// Vuetify Vue.use(Vuetify);

// set bootstrap
// Vue.use(BootstrapVue);
// Vue.use(IconsPlugin);

// Use VuePaginate
Vue.use(VuePaginate);

// Mixins
Vue.mixin(RoleMixin);

// Config
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  mounted() {
    AOS.init();
  },
}).$mount('#app');
