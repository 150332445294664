// Store
import store from '@/store';

const whiteList = [
  '404',
  'landing',
  'legal-privacy-policy',
  'legal-terms-and-conditions',
  'password-recovery-email',
  'logout',
  'password-recovery-reset',
  // 'login',
  // 'signup',
  'verify-email',
  'cart',
  'servicios',
  // 'productos',
  'checkout',
  'pag-thank-you',
  'faq',
  'about-us',
  'carrito',
];

const redirectIfAuth = [
  // 'landing',
  'login',
  'signup',
  // 'checkout',
];

export default {
  async beforeEach(to, from, next) {
    store.dispatch('auth/getStoragedTokens');
    if (to.name === 'login') {
      if (store.getters.token) {
        if (store.getters.isOwner) {
          next('/offices');
        } else {
          next('/orders');
        }
      } else{
        next();
      }

    }else if (whiteList.includes(to.name)) {
      next();
    } else if (redirectIfAuth.includes(to.name)) {
      if (store.getters.token) {
        next('/#/');
        //  next({ name: 'login' });
        // next({ name: 'landing' });
      }
    } else {
      next();
    }
  },

  /**
   * Should redirect to landing if route is not in whitelist.
   */
  shouldRedirect(routeName) {
    return !whiteList.includes(routeName) && routeName !== 'landing';
  },

  afterEach() {},
};
