// Utils
import request from '../utils/request';

/**
 * Implement base CRUD operations
 */
export default class BaseAPI {
  constructor(baseURI) {
    this.baseURI = `v1/${baseURI}`;
    this.request = request;
  }

  /**
   * Retrieve all objects
   * Recuperar todos los objetos
   */
  list(params = {}) {
    return this.request.get(this.baseURI, { params });
  }

  /**
   * Retrieve specific object
   * recuperar un objeto específico
   */
  detail(id, params = {}) {
    return this.request.get(`${this.baseURI}/${id}`, { params });
  }

  /**
   * Create a new object
   * Crear un nuevo objeto
   */
  create(data) {
    return this.request.post(this.baseURI, data);
  }

  /**
   * Update object
   * Actualizar objeto
   */
  update(id, data) {
    return this.request.put(`${this.baseURI}/${id}`, data);
  }

  /**
   * Delete object
   * Eliminar objeto
   */
  delete(id) {
    return this.request.delete(`${this.baseURI}/${id}`);
  }
}
