// Libraries
import axios from 'axios';

// Config
import config from '@/config';

// Store
import store from '@/store';

const service = axios.create({
  baseURL: config.HOST,
});
// solicitud de servicio de axios
service.interceptors.request.use(
  (requestConfig) => {
    if (store.getters.token) {
      /* eslint-disable no-param-reassign */
      requestConfig.headers.Authorization = `Bearer ${store.getters.token}`;
    }

    return requestConfig;
  },
  (error) => {
    if (config.DEBUG) {
      console.error('AXIOS: ', error);
    }
    return Promise.reject(error);
  },
);
// respuesta de servicio de axios
service.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (config.DEBUG) {
      console.error('AXIOS: ', error.response);
    }

    if (error.response.status === 401) {
      store.dispatch('auth/logout');
    }

    return Promise.reject(error.response);
  },
);

export default service;
