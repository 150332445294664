// API
import BaseAPI from './base';

/**
 * Implement Product API
 */
export default class ProductAPI extends BaseAPI {
  constructor() {
    super('products');
  }

  /**
   * Get available services.
   * Obtenga los servicios disponibles.
   */

  services(params = {}) {
    return this.request.get('v1/services', { params });
  }
  /**
   * Get available productos de un servicio.
   * Obtenga los servicios disponibles.
   */

  products(id, params) {
    return this.request.get(`${this.baseURI}/${id}/products`, { params });
  }
  /**
   * Get all products.
   * Obtenga todos los productos.
   * se puede filtrar por oficina y servicio
  */

  productsAll(params) {
    return this.request.get('v1/productosAll/', { params });
  }

  productsServiceFilter(id) {
    return this.request.get(`v1/productosAll/?service_id=${id}`);
  }

  productsOfficeFilter(id) {
    return this.request.get(`v1/productosAll/?office_id=${id}`);
  }

  productsOfficeAndServiceFilter(idOffice, idService) {
    return this.request.get(`v1/productosAll/?office_id=${idOffice}&service_id=${idService}`);
  }

  /**
  * Obtener todos los productos de todas las oficinas
  */
  allProducts(params) {
    return this.request.get(`${this.baseURI}/products`, { params });
  }
}
