/* eslint-disable implicit-arrow-linebreak */

// Libraries
import Vue from 'vue';
import VueRouter from 'vue-router';

// Store
// import store from '@/store';

// Routes
import modules from './modules';
import interceptors from './interceptors';

Vue.use(VueRouter);

const routes = [
  ...modules,
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: 'login' */ '@/views/login/index.vue'),
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import(/* webpackChunkName: 'logout' */ '@/views/login/logout.vue'),
  },
  {
    path: '/legal/privacidad',
    name: 'legal-privacy-policy',
    component: () => import('@/views/legal/privacy.vue'),
  },
  {
    path: '/legal/terminos',
    name: 'legal-terms-and-conditions',
    component: () => import('@/views/legal/terms.vue'),
  },
  {
    path: '/password-recovery/email',
    name: 'password-recovery-email',
    component: () =>
      import(
        /* webpackChunkName: 'password-recovery-email' */ '@/views/password-recovery/Email.vue'
      ),
  },
  {
    path: '/password-recovery/reset/:token',
    name: 'password-recovery-reset',
    component: () =>
      import(
        /* webpackChunkName: 'password-recovery-reset' */ '@/views/password-recovery/Reset.vue'
      ),
    props: true,
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: 'signup' */ '@/views/signup/index.vue'),
    /*
    beforeEnter: (to, _, next) => {
      if (to.name === 'signup') {
        // comprobar su en store/modules/signup.js existe cart.length
        const cart = store.state.signup.cart.length;
        console.log('cart en signup', cart);
        if (cart > 0) {
          return next();
        }
        return next({ path: '/cart' });
      }
      return next({ path: '/cart' });
    },
    */
  },
  {
    path: '/verify-email/:token',
    name: 'verify-email',
    component: () =>
      import(/* webpackChunkName: 'verify-email' */ '@/views/verify-email/index.vue'),
    props: true,
  },
  {
    path: '/',
    name: 'landing',
    component: () => import(/* webpackChunkName: 'landing' */ '@/views/landing/index.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: '404' */ '@/views/error/404.vue'),
  },
  {
    path: '/servicios',
    name: 'servicios',
    component: () => import(/* webpackChunkName: 'servicios' */ '@/views/servicios/index.vue'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import(/* webpackChunkName: 'checkout' */ '@/views/checkout/index.vue'),
  },
  {
    path: '/pag-thank-you',
    name: 'pag-thank-you',
    component: () =>
      import(/* webpackChunkName: 'pag-thank-you' */ '@/views/pag-thank-you/index.vue'),
  },
  {
    path: '/faq',
    name: 'about-us',
    component: () => import(/* webpackChunkName: 'about-us' */ '@/views/about-us/index.vue'),
  },
];

const router = new VueRouter({
  routes,
});

// Interceptors
router.afterEach(interceptors.afterEach);
router.beforeEach(interceptors.beforeEach);

export default router;
