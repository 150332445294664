import ProductAPI from '@/api/product';
import EnviosApi from '@/api/envios';

const initialState = () => ({
  address: {
    street: null,
    outer_number: null,
    inner_number: null,
    suburb: null,
    municipality: null,
    city: null,
    state: null,
    country: null,
    postal_code: null,
  },
  customer: {
    email: null,
    mobile_phone: null,
    name: null,
    password: null,
    postal_code: null,
    uuid: null,
  },
  date: null,
  discount: null,
  individualSelection: false,
  office: {},
  offices: [],
  services: [],
  serviceId: null,
  products: [],
  step: 1,
  time_end: null,
  time_start: null,
  total: 0,
  form: {
    clothes_quantity: null,
    key: null,
    name: null,
    service_id: null,
    unit_price: null,
    uuid: null,
  },
  cart: [],
  initialCartFilled: false,
  dataBsDismiss: null,
  envio: [],
});

const getWapiduDataFromSession = () => {
  let wapiduData = {};
  try {
    wapiduData = JSON.parse(window.localStorage.getItem('wapiduData'));
  } catch (error) {
    console.warn(error);
    try {
      window.localStorage.setItem('wapiduData', '{}');
    } catch (error2) {
      console.warn(error2);
    }
  }
  const defaultWapiduData = {
    cart: [],
  };
  return {
    ...defaultWapiduData,
    ...wapiduData,
  };
};
const syncWapiduDataToSession = (propsToUpdate) => {
  let wapiduData = getWapiduDataFromSession();
  wapiduData = {
    ...wapiduData,
    ...propsToUpdate,
  };
  window.localStorage.setItem('wapiduData', JSON.stringify(wapiduData));
};
const getCartFromSession = () => {
  const wapiduData = getWapiduDataFromSession();
  return wapiduData.cart;
};
const syncCartToSession = (context) => {
  const cartItems = JSON.parse(JSON.stringify(context.state.cart));
  syncWapiduDataToSession({ cart: cartItems });
};
export default {
  namespaced: true,

  state: initialState(),

  getters: {
    total(state) {
      if (!state.individualSelection) return 35;

      let total = state.products.reduce(
        // eslint-disable-next-line no-return-assign, no-param-reassign
        (sum, item) => (sum += item.product.unit_price_normal * item.quantity),
        0,
      );

      if (state.discount) {
        const discountAmount = total * (state.discount.discount / 100);
        total -= discountAmount;
      }

      return total < 0 ? 0 : total;
    },

    productOnCart(state) {
      return state.cart.map((item) => ({
        ...item,
      }));
    },

    cartTotalUrgent(state, getters) {
      return getters.productOnCart.reduce(
        (total, current) => total + current.unit_price_urgent * current.clothes_quantity,
        0,
      );
    },

    cartTotal(state, getters) {
      return getters.productOnCart.reduce(
        (total, current) => total + current.unit_price * current.clothes_quantity,
        0,
      );
    },
  },
  mutations: {
    setAddress(state, address) {
      state.address = address;
    },
    setCustomer(state, customer) {
      state.customer = customer;
      if (!state.address.postal_code && customer.postal_code) {
        state.address.postal_code = customer.postal_code;
      }
    },
    setDiscount(state, discount) {
      state.discount = discount;
    },

    setOffice(state, office) {
      state.office = office;
    },

    setOffices(state, offices) {
      state.offices = offices;
    },

    setStep(state, step) {
      state.step = step;
    },

    nextStep(state) {
      state.step += 1;
    },

    previousStep(state) {
      state.step -= 1;
    },

    getProductos(state, products) {
      state.products = products;
    },

    initialCartFilled(state) {
      state.initialCartFilled = true;
    },

    getServices(state, service) {
      state.services = service;
    },
    getEnvios(state, envios) {
      state.envio = envios;
    },

    setProducts(state, products) {
      state.products = products;
    },

    addProduct(state, item) {
      const exists = state.products.find((p) => p.product.uuid === item.product.uuid);

      if (!exists) {
        state.products.push(item);
      }
    },

    removeAllProducts(state) {
      state.products = [];
    },

    removeProduct(state, product) {
      state.products = state.products.filter((p) => p !== product);
    },

    updateQuantity(state, { item, operation }) {
      const product = state.products.find((p) => p === item);
      product.quantity += operation;

      if (product.quantity <= 0) {
        product.quantity = 1;
      }
    },
    /* eslint-disable no-param-reassign */
    incrementProduct(state, product) {
      /* eslint-disable no-plusplus */
      product.clothes_quantity++;
    },

    decrementProduct(state, product) {
      /* eslint-disable no-plusplus */
      product.clothes_quantity--;
    },
    /* eslint-enable no-param-reassign */

    addProductToCart(state, product) {
      const productData = {
        clothes_quantity: product.clothes_quantity ? product.clothes_quantity : 1,
        id: product.id ? product.id : product.uuid,
        key: product.key,
        name: product.name,
        unit_price: product.unit_price_normal ? product.unit_price_normal : product.unit_price,
        unit_price_urgent: product.unit_price_urgent,
        img_url: product.img_url,
      };
      if (product?.service?.name) {
        productData.service = product.service.name;
        productData.service_id = product.service.id;
      } else {
        productData.service = product.service;
        productData.service_id = product.service_id;
      }
      state.cart.push(productData);
    },
    removeProductCart(state, index) {
      state.cart.splice(index, 1);
    },

    reset(state) {
      const newState = initialState();

      Object.keys(state).forEach((key) => {
        state[key] = newState[key];
      });
    },
    // Vaciar el carrito
    clearCart(state) {
      state.cart = [];
    },
  },

  actions: {
    async getAllProducts({ commit, state }) {
      try {
        this.productAPI = new ProductAPI();
        const productdata = await this.productAPI.productsAll();
        const dataResults = productdata.results.filter((item) => item.is_active === true);
        commit('getProductos', dataResults);
        if (state && !state.initialCartFilled) {
          commit('initialCartFilled');
          console.log('initialCartFilled');
          const initialCartItems = getCartFromSession();
          if (state.cart.length === 0 && initialCartItems.length > 0) {
            let ii = initialCartItems.length;
            while (ii > 0) {
              ii--;
              const product = JSON.parse(JSON.stringify(initialCartItems[ii]));
              console.log('addProductToCart');
              console.log(product);
              commit('addProductToCart', product);
            }
          }
        }
      } catch (error) {
        console.warn(error);
        this.$store.dispatch('notification/notifyError');
      }
    },

    async getService({ commit }) {
      this.productAPI = new ProductAPI();
      const servicestdata = await this.productAPI.services();
      const dataResults = servicestdata.results;
      commit('getServices', dataResults);
    },

    addProductoCar(context, product) {
      const uuid = product.uuid ? product.uuid : product.id;
      const item = context.state.cart.find((p) => {
        const pUuid = p.id ? p.id : p.uuid;
        return uuid === pUuid;
      });
      let newProduct;
      /*
      console.log('addProductoCar', uuid);
      console.log('In cart:');
      console.log(JSON.parse(JSON.stringify(context.state.cart)));
      console.log('Product Found;');
      console.log(item);
      */
      if (item) {
        /*
        console.log('Increment Product');
        console.log(item);
        */
        context.commit('incrementProduct', item);
      } else {
        newProduct = JSON.parse(JSON.stringify(product));
        /*
        console.log('New Product');
        console.log(newProduct);
        */
        context.commit('addProductToCart', newProduct);
      }
      syncCartToSession(context);
    },

    incrementProductcart(context, product) {
      const item = product;
      if (item.clothes_quantity >= 0) {
        context.commit('incrementProduct', item);
      }
      syncCartToSession(context);
    },

    decrementProductcart(context, product, index) {
      const item = product;
      if (item.clothes_quantity > 1) {
        context.commit('decrementProduct', item);
      } else {
        context.commit('removeProductCart', index);
      }
      syncCartToSession(context);
    },

    removeProductFromCart(context, index) {
      context.commit('removeProductCart', index);
      syncCartToSession(context);
    },
    getProfileFromStorage() {
      let profileData = null;
      try {
        profileData = JSON.parse(window.localStorage.getItem('profile'));
      } catch (error) {
        console.warn(error);
      }
      if (profileData && profileData?.created_at) {
        return profileData;
      }
      return null;
    },
    getCartFromStorage() {
      let wapiduData = null;
      try {
        wapiduData = JSON.parse(window.localStorage.getItem('wapiduData'));
      } catch (error) {
        console.warn(error);
      }
      if (wapiduData && wapiduData?.cart) {
        return wapiduData.cart;
      }
      return [];
    },
    async getEnvioss({ commit }) {
      this.EnviosApi = new EnviosApi();
      const enviodata = await this.EnviosApi.enviosAll();
      const dataResultsEnvio = enviodata.results.filter((item) => item.cobertura === true);
      commit('getEnvios', dataResultsEnvio);
    },

    // addProduct() {
    //   if (!this.form.product) return;

    //   this.$store.commit('signup/addProduct', this.form);
    //   this.form = initialForm();
    // },

    // removeProduct(product) {
    //   this.$store.commit('signup/removeProduct', product);
    // },

    // updateQuantity(item, operation) {
    //   this.$store.commit('signup/updateQuantity', { item, operation });
    // },

    async applyDiscount() {
      this.$store.commit('showLoader');
      try {
        this.$store.commit('signup/setDiscount', null);
        this.discount = null;
        this.discount = await this.discountAPI.getByCode({
          code: this.discount_code,
          // office: this.office.uuid,
        });
        console.log('este es ek descyebt0', this.discount);
        this.discount_alert = {
          message: `Descuento del ${this.discount.discount}% aplicado.`,
          type: 'green--text',
        };
        this.$store.commit('signup/setDiscount', this.discount);
      } catch (error) {
        console.log(error);
        this.discount_alert = {
          message: error.data.message,
          type: 'red--text',
        };
      }
      this.$store.commit('hideLoader');
    },
  },
};
